.bg{
    width: 80%;
    margin: 50px auto;
}
.lg-react-element  {
    /* display: inline-block;
    grid-template-columns: repeat(auto-fill, minmax(320px,1fr));
    grid-auto-rows: 320px;
    width: 80%;
    margin: 50px auto; */   
    column-count: 4;
    column-gap: 0;
}

.gallery-item{
    margin: 0 5px 10px 5px;
    cursor: pointer;
}

.gallery-img{
    width: 100%;
    /* position: relative;
    left: 0;
    top: 0; */
}