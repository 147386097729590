body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  word-wrap: break-word;
  background-color: #1a1c1b;
  color: white;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
.title-h1 {
  color: white !important;
  font-size: 32px !important;
}
.feature6-text {
  color: white !important;
}
.feature6-title-bar {
  background-color: #1a1c1b !important;
}
.content5-block-content {
  background-color: #3c4848 !important;
  color: white !important;
}
.content5-block-content span {
  background-color: #3c4848 !important;
}
/* 详细页图片或框框的样式;
*/
.point {
  float: left;
  width: 8px;
  height: 8px;
  opacity: 0.5;
  cursor: pointer;
  pointer-events: auto;
  -webkit-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), background 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), background 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  margin: 6px auto;
  background: #1890ff;
  border-radius: 100%;
}
.point.active {
  opacity: 1;
  background: #1890ff;
}
.point-wrapper {
  position: fixed;
  z-index: 9998;
  top: 0;
  right: 16px;
  width: 10px;
  display: -webkit-flex;
  display: flex;
  height: 100%;
  -webkit-align-items: center;
          align-items: center;
  pointer-events: none;
}
.point-left {
  left: 16px;
}
.point-rect {
  border-radius: 0;
}
.point-prismatic {
  border-radius: 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.point-stroke {
  border: 2px solid #1890ff;
  background: transparent;
}
.point-small {
  width: 6px;
}
.point-small.point {
  height: 6px;
}
.point-large {
  width: 10px;
}
.point-large.point {
  height: 10px;
}
@media screen and (max-width: 767px) {
  .point-wrapper {
    display: none;
  }
}
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.header0 {
  background: #001529;
  width: 100%;
  z-index: 1;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  position: relative;
  top: 0;
}
.header0 .home-page {
  padding: 0 24px;
}
.header0-logo {
  display: inline-block;
  position: relative;
  width: 150px;
  line-height: 64px;
}
.header0-logo img {
  vertical-align: middle;
  display: inline-block;
}
.header0-logo a {
  display: block;
}
.header0-menu {
  float: right;
}
.header0-menu > .ant-menu {
  line-height: 62px;
  background: transparent;
  color: #fff;
  height: 64px;
  border-bottom-color: transparent;
  position: relative;
}
.header0-menu > .ant-menu a {
  color: #fff;
}
.header0-menu > .ant-menu a:hover {
  color: #1890ff;
}
.header0 .ant-menu-item-selected a {
  color: #1890ff;
}
@media screen and (max-width: 767px) {
  .header0-logo {
    z-index: 101;
  }
  .header0.home-page-wrapper .home-page {
    padding: 0 24px;
  }
  .header0 .header0-menu {
    height: auto;
    float: inherit;
    position: relative;
    left: -24px;
    width: calc(100% + 48px);
    opacity: 0;
    -webkit-transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header0 .header0-menu li {
    padding: 0 24px;
  }
  .header0 .header0-menu li.ant-menu-submenu {
    padding: 0;
  }
  .header0 .header0-menu .ant-menu-submenu .ant-menu-sub {
    padding: 0 24px;
  }
  .header0-mobile-menu {
    width: 16px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 100;
  }
  .header0-mobile-menu em {
    display: block;
    width: 100%;
    height: 2px;
    background: #fff;
    margin-top: 4px;
    -webkit-transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header0-mobile-menu :first-child {
    margin-top: 0;
  }
  .header0 .ant-menu {
    height: auto;
    overflow: hidden;
    background: #001529;
  }
  .header0 .ant-menu .ant-menu-item-selected {
    border: none;
  }
  .header0 .ant-menu a {
    color: #fff;
  }
  .header0 .ant-menu a:hover {
    color: #fff;
  }
  .header0 .ant-menu .ant-menu-item-selected a {
    color: #fff;
  }
  .header0 .open {
    height: auto;
  }
  .header0 .open .header0-mobile-menu em:nth-child(1) {
    -webkit-transform: translateY(6px) rotate(45deg);
            transform: translateY(6px) rotate(45deg);
  }
  .header0 .open .header0-mobile-menu em:nth-child(2) {
    opacity: 0;
  }
  .header0 .open .header0-mobile-menu em:nth-child(3) {
    -webkit-transform: translateY(-6px) rotate(-45deg);
            transform: translateY(-6px) rotate(-45deg);
  }
  .header0 .open > .header0-menu {
    opacity: 1;
    pointer-events: auto;
  }
}
.banner0 {
  width: 100%;
  height: 100vh;
  position: relative;
  text-align: center;
  border-color: #666;
  background-image: url("https://source.unsplash.com/1920x1080/?landscape");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}
.banner0 .banner0-text-wrapper {
  display: inline-block;
  position: absolute;
  top: 20%;
  margin: auto;
  left: 0;
  right: 0;
  font-size: 24px;
  color: #fff;
  width: 550px;
}
.banner0 .banner0-text-wrapper > .queue-anim-leaving {
  position: relative !important;
}
.banner0 .banner0-title {
  width: 350px;
  min-height: 60px;
  margin: auto;
  display: inline-block;
  font-size: 40px;
  position: relative;
}
.banner0 .banner0-content {
  margin-bottom: 20px;
  word-wrap: break-word;
  min-height: 24px;
}
.banner0 .banner0-button {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  box-shadow: 0 0 0 transparent;
  font-size: 16px;
  height: 40px;
  -webkit-transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner0 .banner0-button:hover {
  color: #fff;
  border-color: #fff;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(50, 250, 255, 0.75);
}
.banner0 .banner0-button:focus {
  color: #fff;
  border-color: #fff;
}
.banner0 .banner0-button.queue-anim-leaving {
  width: auto;
}
.banner0 .banner0-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  color: #fff;
}
@media screen and (max-width: 767px) {
  .banner0 {
    background-attachment: inherit;
  }
  .banner0 .banner0-text-wrapper {
    width: 90%;
  }
  .banner0 .banner0-title {
    width: 90%;
    left: 0;
  }
}
.jinrishici {
  margin-bottom: 20px;
  word-wrap: break-word;
  min-height: 24px;
  color: white;
}
.content13-wrapper {
  min-height: 380px;
  background: url("https://gw.alipayobjects.com/zos/rmsportal/ZsWYzLOItgeaWDSsXdZd.svg") no-repeat bottom;
  background-size: cover;
  background-size: 100%;
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
  padding: 96px 0;
}
.content13-wrapper.home-page-wrapper .title-wrapper {
  margin-bottom: 32px;
}
.content13-wrapper .title-content {
  line-height: 32px;
}
@media screen and (max-width: 767px) {
  .content13-wrapper {
    padding-bottom: 0;
  }
}
.feature6-wrapper {
  min-height: 360px;
  margin: 0 auto;
  overflow: hidden;
}
.feature6-wrapper.home-page-wrapper .home-page {
  padding: 64px 24px;
}
.feature6-title {
  display: inline-block;
}
.feature6-title-wrapper {
  text-align: center;
  margin-bottom: 48px;
}
.feature6-title-text {
  display: inline-block;
  margin: 0 72px;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.65);
  -webkit-transition: color 0.45s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: color 0.45s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
}
.feature6-title-text.active {
  color: #1890ff;
}
.feature6-title-bar {
  height: 6px;
  background: #1890ff;
  width: 20%;
  margin: auto;
  display: block;
}
.feature6-title-bar-wrapper {
  position: relative;
  margin-top: 8px;
  -webkit-transition: left 0.45s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: left 0.45s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.feature6-number,
.feature6-text {
  text-align: center;
}
.feature6-number {
  font-size: 48px;
  color: #1890ff;
  font-weight: 600;
}
.feature6-number-wrapper {
  text-align: center;
}
.feature6-text {
  font-size: 20px;
}
.feature6-unit {
  font-size: 16px;
  color: #1890ff;
}
.feature6 .ant-carousel .slick-dots-bottom {
  bottom: -24px;
}
.feature6 .ant-carousel .slick-dots li button {
  background: rgba(24, 144, 255, 0.3);
}
.feature6 .ant-carousel .slick-dots li.slick-active button {
  background: #1890ff;
}
@media screen and (max-width: 767px) {
  .feature6-wrapper {
    padding-bottom: 0;
    min-height: 580px;
  }
  .feature6-wrapper.home-page-wrapper .home-page {
    padding: 56px 24px 64px;
  }
  .feature6-title-text {
    margin: 0 14px;
  }
  .feature6-title-bar {
    width: 40%;
  }
  .feature6-number-wrapper {
    margin-bottom: 16px;
  }
}
.content5-wrapper .content5 > p {
  text-align: center;
}
.content5-wrapper .content5-img-wrapper {
  margin: 0 auto;
  left: 0;
  right: 0;
}
.content5-wrapper .content5-img-wrapper .block {
  margin-bottom: 24px;
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content {
  display: block;
  background: #fff;
  border-radius: 4px;
  text-align: center;
  position: relative;
  overflow: hidden;
  border-radius: 6px;
  border: 1px solid #e9e9e9;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition: box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  border: none;
  color: rgba(0, 0, 0, 0.65);
  transition: box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content:hover {
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content > span {
  width: 100%;
  height: 160px;
  display: block;
  background: #e9e9e9;
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content p {
  width: 100%;
  line-height: 30px;
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content:hover p {
  bottom: 0;
}
@media screen and (max-width: 767px) {
  .content5-wrapper {
    overflow: hidden;
  }
  .content5-wrapper .content5 ul li {
    display: block;
    width: 100%;
    padding: 2%;
  }
  .content5-wrapper .content5 ul li span {
    height: 168px;
  }
  .content5-wrapper .content5 ul li p {
    position: relative;
    bottom: 0;
  }
}
.footer1-wrapper {
  background: #001529;
  overflow: hidden;
  position: relative;
  min-height: 360px;
  color: #999;
}
.footer1-wrapper .footer1 .home-page {
  padding: 64px 24px 80px;
}
.footer1-wrapper .block {
  padding: 0 32px;
}
.footer1-wrapper .block .logo {
  max-width: 180px;
}
.footer1-wrapper .block .slogan {
  font-size: 12px;
  margin-top: -20px;
}
.footer1-wrapper .block > h2 {
  margin-bottom: 24px;
  color: #ccc;
}
.footer1-wrapper .block a {
  color: #999;
  margin-bottom: 12px;
  float: left;
  clear: both;
}
.footer1-wrapper .block a:hover {
  color: #1890ff;
}
.footer1-wrapper .copyright-wrapper {
  width: 100%;
  border-top: 1px solid rgba(233, 233, 233, 0.1);
}
.footer1-wrapper .copyright-wrapper .home-page {
  padding: 0 24px;
  overflow: hidden;
}
.footer1-wrapper .copyright-wrapper .copyright {
  height: 80px;
  text-align: center;
  line-height: 80px;
}
@media screen and (max-width: 767px) {
  .footer1 {
    min-height: 550px;
  }
  .footer1-wrapper .footer1 .home-page {
    padding: 64px 24px 32px;
  }
  .footer1 .logo {
    margin: 0 auto 24px;
  }
  .footer1 .block {
    text-align: center;
    margin-bottom: 32px;
    padding: 0;
  }
  .footer1 > ul {
    width: 90%;
    margin: 20px auto 0;
    padding: 10px 0;
  }
  .footer1 > ul > li {
    width: 100%;
  }
  .footer1 > ul > li h2 {
    margin-bottom: 10px;
  }
  .footer1 > ul > li li {
    display: inline-block;
    margin-right: 10px;
  }
  .footer1 .copyright-wrapper .home-page {
    padding: 0;
  }
  .footer1 .copyright-wrapper .home-page .copyright {
    font-size: 12px;
  }
  .footer1 .copyright span {
    width: 90%;
  }
}

.bg{
    width: 80%;
    margin: 50px auto;
}
.lg-react-element  {
    /* display: inline-block;
    grid-template-columns: repeat(auto-fill, minmax(320px,1fr));
    grid-auto-rows: 320px;
    width: 80%;
    margin: 50px auto; */   
    -webkit-column-count: 4;   
            column-count: 4;
    grid-column-gap: 0;
    -webkit-column-gap: 0;
            column-gap: 0;
}

.gallery-item{
    margin: 0 5px 10px 5px;
    cursor: pointer;
}

.gallery-img{
    width: 100%;
    /* position: relative;
    left: 0;
    top: 0; */
}
.bg{
    width: 80%;
    margin: 50px auto;
}
.lg-react-element  {
    /* display: inline-block;
    grid-template-columns: repeat(auto-fill, minmax(320px,1fr));
    grid-auto-rows: 320px;
    width: 80%;
    margin: 50px auto; */   
    -webkit-column-count: 4;   
            column-count: 4;
    grid-column-gap: 0;
    -webkit-column-gap: 0;
            column-gap: 0;
}

.gallery-item{
    margin: 0 5px 10px 5px;
    cursor: pointer;
}

.gallery-img{
    width: 100%;
    /* position: relative;
    left: 0;
    top: 0; */
}
.tools-collection-item-card-a:not(ant-card) {
  -webkit-transition: box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.tools-collection-item-card-a:not(ant-card):hover {
  -webkit-transition: box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}
.tools-collection-item-card-picture {
  margin: auto;
  margin-bottom: 8px;
  display: block;
  width: 160px;
  height: 160px;
}
.tools-collection-item-card-picture svg {
  width: 160px;
  height: 160px;
}

body {
  background-color: #1a1c1b;
}
.bg {
  margin: auto;
  margin-top: 50px;
  min-height: 100%;
}
.timecounter-row {
  text-align: center;
  margin-bottom: 50px;
}

body {
  background-color: #1a1c1b;
}
.bg {
  margin: auto;
  margin-top: 50px;
  min-height: 100%;
}
.crypto-row {
  margin-bottom: 50px;
}
.tool-crypto-btn {
  margin-right: 20px;
}

/* .App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.main-component{
  padding: 24px;
  min-height: 100vh;
  max-width: 1200px;
  margin: auto;
}
